<template>
  <div>
    <CompletedTest v-if="!viewRemarks" :context="this" />
    <Remarks v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompletedTest from "./CompletedTest.vue";
import Remarks from "../views/Remarks.vue";
export default {
  components: {
    CompletedTest,
    Remarks,
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
    ...mapGetters("testVUEX", {
      viewRemarks: "getviewRemarks",
    }),
  },
  beforeRouteLeave(to, from, next) {
    next(false);
  },
  data() {
    return {};
  },
  created() {
    history.pushState(location.href, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
  methods: {
    openWebsite() {
      try {
        if (this.testDetails.config.allowCandidateRemarks) {
          window.removeEventListener("beforeunload", this.handleBeforeUnload);
          this.$store.commit("testVUEX/setViewRemarks", true); // this.$router.push(
          //   {
          //     path: "/remarks",
          //   },
          //   // () => {
          //   //   this.$router.go(0);
          //   // }
          // );
        } else {
          this.$store.commit("testVUEX/setViewRemarks", false); // this.$router.push(

          location.replace("https://candidate.simplifymycareer.com");
        }
      } catch (error) {
        location.replace("https://candidate.simplifymycareer.com");
        console.log(error);
      }
    },
  },
  // mounted() {
  //   (function() {
  //     if (window.localStorage) {
  //       if (!localStorage.getItem("firstLoad")) {
  //         localStorage["firstLoad"] = true;
  //         window.location.reload();
  //       } else localStorage.removeItem("firstLoad");
  //     }
  //   })();
  // },
};
</script>

<style scoped>
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}

.completeddetails-container {
  height: calc(100vh - 123px);
  overflow: auto;
  border-radius: 14px;
  background: url("../assets/newui-img/checkkk.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  width: 100%;
}
.comp-maintxt {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #3a3c40;
}
.comp-subtxt {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #3a3c40;
}
</style>
